<template>
  <div>
    <div class="row">
      <div class="col-lg-12">
        <KTPortlet v-bind:title="$t('MENU.SECURITY_INCIDENTS')">
          <template v-slot:body>
            <div class="col-md-10 mt-2" v-if="securityWarning">
              <b-alert show variant="danger">
                <b>{{ $t("SECURITY.ACTION") }}</b>
              </b-alert>
              <b-alert show variant="secondary">
                <b>{{ $t("SECURITY.INFO") }}</b>
              </b-alert>
            </div>
            <div class="col-md-10 mt-2" v-if="!securityWarning">
              <b-alert show variant="primary">
                <b>{{ $t("SECURITY.NONE") }}</b>
              </b-alert>
            </div>
            <div class="row table-filter">
              <div class="col-sm-6">
                <b-form-group
                  :label="$t('SECURITY.MONITOR_LOGINS')"
                  label-for="select-status"
                >
                  <b-form-select
                    id="select-status"
                    v-model="statusSelected"
                    :options="statusData"
                    value-field="value"
                    text-field="name"
                  ></b-form-select>
                </b-form-group>
              </div>
              <div class="col-sm-6">
                <b-button
                  variant="primary"
                  @click="updateSecurityWarningStatus()"
                  style="margin-top: 25px"
                >
                  {{ $t("COMMON.SAVE") }}
                </b-button>
              </div>
            </div>
            <div class="row table-filter">
              <div class="col-sm-6"></div>
              <div class="col-sm-6">
                <v-text-field
                  v-model="search"
                  append-icon="search"
                  :label="$t('COMMON.SEARCH')"
                  class="cus-searchBox"
                  single-line
                  hide-details
                ></v-text-field>
              </div>
            </div>
            <v-data-table
              v-model="selected"
              :headers="headers"
              :items="securityWarnings"
              :single-select="singleSelect"
              :search="search"
              item-key="name"
              class="elevation-1"
              :footer-props="{
                showFirstLastPage: true,
              }"
              @click:row="inspectUser"
            >
            </v-data-table>
          </template>
        </KTPortlet>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";
import KTPortlet from "@/views/partials/content/Portlet.vue";
import { postRequest } from "@/api/request.js";
import JwtService from "@/common/jwt.service";

/**
 * Sample widgets data source
 */
export default {
  name: "home",
  components: {
    KTPortlet,
  },
  data() {
    return {
      statusSelected: 0,
      search: "",
      singleSelect: false,
      selected: [],
      headers: [
        { text: this.$t("COMMON.USERNAME"), value: "username" },
        { text: this.$t("COMMON.DOMAIN"), value: "domain" },
        { text: this.$t("SECURITY.COUNTRIES"), value: "mycnt" },
        { text: this.$t("SECURITY.SENT"), value: "sendlimit" },
      ],
      securityWarnings: [],
    };
  },
  created() {
    // this.$i18n.locale = localStorage.language;
    const token = localStorage.id_token;
    const secutiyWarningLevel = localStorage.securityWarningLevel;
    this.statusSelected = secutiyWarningLevel;
    //this.getSecurityWarningLevel();
    postRequest({ action: "getSecurityWarnings", token: token }).then((res) => {
      if (res && res.returncode) {
        this.securityWarnings = res.returndata;
      }
    });
  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: this.$t("MENU.SECURITY_INCIDENTS") },
    ]);
  },
  computed: {
    securityWarning: function () {
      return localStorage.securityWarning == 1;
    },
    statusData: function () {
      return [
        { name: this.$t("COMMON.DISABLED"), value: 0 },
        { name: this.$t("SECURITY.ENABLED_COUNTRY", {num: 1}), value: 1 },
        { name: this.$t("SECURITY.ENABLED_COUNTRY", {num: 2}), value: 2 },
        { name: this.$t("SECURITY.ENABLED_COUNTRY", {num: 3}), value: 3 },
        { name: this.$t("SECURITY.ENABLED_COUNTRY", {num: 4}), value: 4 },
        { name: this.$t("SECURITY.ENABLED_COUNTRY", {num: 8}), value: 8 },
        { name: this.$t("SECURITY.ENABLED_COUNTRY", {num: 10}), value: 10 },
      ];
    },
  },
  methods: {
    inspectUser(row) {
      window.open(
        "/#/logs/lastlogins/" + row.username + "@" + row.domain,
        "_blank"
      );
    },
    updateSecurityWarningStatus() {
      const status = this.statusSelected;
      postRequest({
        action: "updateSecurityWarningLevel",
        token: localStorage.id_token,
        level: this.statusSelected,
      }).then((res) => {
        if (res && res.returncode) {
          this.makeToastVariant("success", res.returndata);
          JwtService.saveSecurityWarningLevel(status);
          //this.getSecurityWarningLevel();
        } else {
          this.makeToastVariant("danger", res.returndata);
        }
      });
    },
    /*getSecurityWarningLevel() {
      postRequest({
        action: "getSecurityWarningLevel",
        token: localStorage.id_token,
      }).then((res) => {
        if (res && res.returncode) {
          this.statusSelected = res.returndata;
        } else {
          this.makeToastVariant("danger", res.returndata);
        }
      });
    },*/
    /* ----notify---- */
    makeToastVariant(variant = null, msg) {
      let title = this.$t("COMMON.ERROR");
      if (variant == "success") {
        title = this.$t("COMMON.SUCCESS");
      }
      this.$bvToast.toast(msg, {
        title: title,
        variant: variant,
        toaster: "b-toaster-top-center",
        // autoHideDelay: 5000,
        solid: true,
      });
    },
  },
};
</script>
